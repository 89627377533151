<template>
  <div>
    <div class="news">
      <div class="title">
        {{active.name}}
      </div>
      <div class="info">{{active.h2}}</div>
      <div class="img">
        <img :src="active.img" alt="" style="max-width: 333px;">
        <!--<div class='text-left mt-2'>
          <a :href="active.href">
            <img width="50%" src="../assets/Google_Scholar_logo.png" alt="">
          </a>
        </div>-->
      </div>
      <div class="content">
        <p v-for="item in active.line" :key="item">{{item}}</p>
      </div>
    </div>
    <Footers />
  </div>
</template>
<script>
import Footers from "@/components/Footers"
export default {
  data () {
    return {
      list: [
        {
          id: 1,
          name: 'Shirley Liu, PhD',
          img: require("../assets/team/1/ShirleyLiuPhD.png"),
          h2: "Chief Executive Officer, Co-Founder",
          line: [
            "Dr. Xiaole Shirley Liu co-founded GV20 Therapeutics in 2016 and became the CEO of GV20 in 2022. She was a Professor of Biostatistics and Computational Biology at Dana-Farber Cancer Institute and Harvard T.H. Chan School of Public Health and co-director of the Center for Functional Cancer Epigenetics at Dana-Farber Cancer Institute. Her computational biology work refined our understanding of hormone receptor therapies, epigenetic inhibitors, gamma-secretase inhibitors, receptor tyrosine kinase inhibitors, and immune checkpoint inhibitors in different cancers.",
            "Dr. Liu is a highly cited researcher with a prodigious publication record that includes more than 200 papers published by her group, many in high-profile journals and highly cited. Shirley received the Sloan Research Fellowship (2008), has been a Breast Cancer Research Foundation Investigator (2017) and became a Fellow of ISCB (2019). In addition, she was recognized with the Benjamin Franklin Award for Open Access in the Life Sciences and the ISCB Innovator Award in 2020. Recently, she was selected by the American Institute for Medical and Biological Engineering (AIMBE) to its 2022 College of Fellows."
          ],
          href:'https://scholar.google.com/citations?user=8XNfVucAAAAJ',
        }, {
          id: 2,
          name: 'Dr. Tengfei Ted Xiao',
          img: require("../assets/team/1/TedXiaoPhD.png"),
          h2: "Chief Technology Officer, Co-Founder",
          line: [
            "Dr. Tengfei Ted Xiao is a Co-Founder and the Chief Technology Officer of GV20 Therapeutics. Ted founded GV20 Therapeutics in 2016, after he finished his postdoctoral research training in Myles Brown lab at Dana-Farber Cancer Institute (DFCI). He was a pioneer of building up a functional CRISPR screening platform at DFCI. His expertise includes cancer genomics, high throughput screening techniques and bioinformatics. In GV20, he leads teams to build an AI-based antibody screening platform and has discovered several novel targets for cancer immunotherapy."
          ],
          href:'https://scholar.google.com/citations?user=rpyPcycAAAAJ',
        }, {
          id: 3,
          name: 'Xingfeng Bao, PhD',
          img: require("../assets/team/1/XingfengBao.png"),
          h2: "Head of Biology",
          line: [
            "Dr. Bao has 20 years of research and development experience in immunotherapy and targeted oncology drugs with a particular focus in early discovery to early clinical development. During his career in industry, he has led the discovery of six development candidates and three IND filings in small molecules and biologics. Prior to joining GV20, Dr. Bao was Executive Director, Head of Cancer Immunology and Pharmacology at H3biomedicine, where he led the company’s discovery and translational biology group focused on cancer immunotherapy and antibody-drug conjugates. In his earlier career, he also held roles of increasing responsibilities from Project Lead to Head of Cancer Immunotherapy Discovery at Eisai’s Oncology Business Unit.",
            "Dr. Bao earned his doctorate in 2002 and completed his postdoctoral fellowship at Sanford-Burnham-Prebys Medical Discovery Institute, La Jolla, California."
          ],
          href:'',
        }, {
          id: 4,
          name: 'Karim Benhadji, MD',
          img: require("../assets/team/1/KarimBenhadji.png"),
          h2: "Chief Medical Officer",
          line: [
            "Dr. Karim Benhadji serves as the Chief Medical Officer of GV20 Therapeutics. He has over 17 years of experience in biopharmaceutical oncology drug development.",
            "He previously served as Senior Vice President, Head of Clinical Development and Operations at Taiho Oncology where he oversaw the development of early and late phase portfolio assets including regulatory fillings leading to the FDA approval of Lytgobi®.",
            "Prior to joining Taiho Oncology, Dr. Benhadji spent over 13 years with Eli Lilly and Company in oncology development roles of increasing responsibility. He was most recently Vice President, Early Phase Oncology and oversaw several immuno-oncology programs.",
            "Dr. Benhadji received his M.D. from the University of Oran. He has completed medical oncology fellowships in Universities of Oran and Paris 7 and holds a Master of Science in Pharmacology and Therapeutics in Oncology from the University of Paris 11."
          ],
          href:'',
        }, {
          id: 5,
          name: 'Jie Chen, MD, MS',
          img: require("../assets/team/1/JieChen.png"),
          h2: "Chief Technical Operations & Quality Officer",
          line: [
            "Dr. Jie Chen serves as the Chief Technical Operations & Quality Officer of GV20 Therapeutics. She brings over 25 years of biopharmaceutical industry experience leading biologics CMC development from DNA to IND to BLA and commercialization, with a track record of numerous successful IND and BLA filings. She previously served as Vice President of CMC Management and then Senior Vice President/Site Head of US Manufacturing and Operations at WuXi Biologics. She was responsible for managing client CMC projects and leading the startup efforts of Wuxi’s US new sites from design, construction, team hiring/building, Lab operation to manufacturing cGMP release and successful cGMP manufacturing production. Prior to joining WuXi Biologics, Dr. Chen spent over 18 years in biopharmaceutical companies in the Greater Boston area including Dyax and Shire, where she led protein sciences, process development, and CMC functions, and managed internal teams and CDMOs.",
            "Dr. Chen received her M.D. from Medical School of Xi’an Jiao Tong University and practiced as an oncologist in China. She completed her post-doctoral training at the University of Pennsylvania and received her Master of Science in Physiology and Neurobiology from Rutgers University."
          ],
          href:'',
        }, {
          id: 6,
          name: 'Dr. Ying Gong',
          img: require("../assets/team/1/YingGong.png"),
          h2: "Chief Business Officer",
          line: [
            "Dr. Gong is the Chief Business Officer of GV20. She is an experienced strategist, drug developer and leader. Her extensive biopharmaceutical experience encompasses multiple therapeutic areas, drug modalities, R&D stages, and functions. Most recently, Dr. Gong served as Project Team Leader, Oncology at Genentech, where she led drug development programs from late-stage research to clinical proof-of-concept as well as business development projects. She also served as Senior Director of Portfolio Strategy and Planning and made significant contributions in shaping Genentech's overall R&D strategy. Prior to that, she held roles in medical affairs, market access, global product strategy, and market planning at Genentech and Roche, and worked on a broad range of projects including new indication launches of Perjeta® and Avastin®. She started her career in industry as a management consultant at Bain & Company.",
            "Dr. Gong earned a Ph.D. in Biochemistry and Molecular Biophysics from the California Institute of Technology, and an M.A. in Molecular Genetics from Smith College. She did her undergraduate studies at Peking University."
          ],
          href:'',
        }, {
          id: 7,
          name: 'Dr. Xihao Sherlock Hu',
          img: require("../assets/team/1/SherlockHuPhD.png"),
          h2: "Chief Information Officer",
          line: [
            "Dr. Xihao Sherlock Hu is the Chief Information Officer of GV20 Therapeutics. Sherlock obtained his Ph.D. in Computer Science from Chinese University of Hong Kong and then joined Prof. Shirley Liu’s lab at Dana-Farber Cancer Institute and Harvard University for postdoctoral training. In 2019, Sherlock was recognized by the Parker Institute as Parker Scholar for his innovative idea in mining tumor-infiltrating B cell receptor repertoires.",
            "Dr. Hu’s expertise in Computational Biology and Immunology has served as the foundation of GV20 AI platform, a unique solution of de novo design fully human functional antibodies using deep learning. In GV20, Sherlock leads the bioinformatics team and the AI team, specialized in AI-based antibody drug design."
          ],
          href:'https://scholar.google.com/citations?hl=en&user=m_F7wUwAAAAJ',
        }, {
          id: 8,
          name: 'Dr. Huawei Qiu',
          img: require("../assets/team/1/HuaweiQiu.png"),
          h2: "SVP Head of Biologics Discovery",
          line: [
            "Dr. Huawei Qiu serves as the Senior Vice President / Head of Biologics Discovery of GV20 Therapeutics. He has over 20 years of experience in Biologics discovery, engineering and early development.",
            "Dr. Qiu previously served as Vice President, Biologics Discovery and Engineering at Palleon Pharmaceuticals, and before that, Vice President of Biologics at Xilio Therapeutics, overseeing Biologics discovery, engineering, early CMC development, external collaboration and partnering. Prior to that, he was a Senior Director at Sanofi/Genzyme where he oversaw protein engineering, developability mitigation, and Biologics research of various therapeutic modalities and their applications in disease areas including rare diseases, immunology, and oncology. He contributed to more than 15 Biologics R&D programs from Discovery to IND filing. He is a co-inventor of over 30 issued U.S. patents or published patent applications, and co-authored more than 35 scientific publications.",
            "Dr. Qiu received his BS degree in Chemistry from Peking University, and PhD in Biochemistry from Texas A&M University.  He did his postdoctoral works at Brown University and Brigham & Women’s Hospital / Harvard Medical School with a NIH/NRSA fellowship.",
          ],
          href:'',
        }, {
          id: 9,
          name: 'Hong Xiao, MS',
          img: require("../assets/team/1/HongXiao.png"),
          h2: "Head of Clinical Operations",
          line: [
            "Hong Xiao has worked in pharmaceutical companies for over 25 years. Before joining GV20 Therapeutics, she spent 17 years in Clinical Research and Clinical Operations at Bristol-Myers Squibb Company, working in multiple disease areas, including Fibrosis, Immuno-Oncology, Infectious Disease and Neuroscience. Hong had opportunities She initiated 3 global ph3 studies, and completed over 27 studies, including 7 First-in-Human studies, 4 global ph2 sites, and many early phase studies, ph1b, ph2a and clinpharm as Clinical Scientist and Operation Manager or Operation Lead. Most recently she led the successful initiation of a ph3 program as Director of Clinical Operations Portfolio Lead. In addition, she managed CROs on outsourced studies.",
            "Hong graduated from the University of Massachusetts at Amherst with a Master’s degree in Molecular and Cellular Biology. In her spare time, she enjoys growing roses, peonies, and other perineal plants in her garden.",
          ],
          href:'',
        }, {
          id: 10
        }, {
          id: 11,
          name: 'Myles Brown, MD',
          img: require("../assets/team/1/MylesBrownMD.png"),
          h2: "Emil Frei III Professor of Medicine at Dana-Farber Cancer Institute and Harvard Medical School",
          line: [
            "Prof. Myles Brown is Director of the Center for Functional Cancer Epigenetics at the Dana-Farber Cancer Institute and the Emil Frei III Professor of Medicine at Dana-Farber Cancer Institute and Harvard Medical School. He completed training in Internal Medicine at the Brigham and Women’s Hospital while doing research with David Livingston at the Dana-Farber. He went on to complete training in Medical Oncology at the Dana-Farber and postdoctoral research with Phillip Sharp at MIT. He subsequently joined the staff of the Dana-Farber and the faculty of Harvard Medical School. From 2002-2010 he served as Chief of the Division of Molecular and Cellular Oncology at the Dana-Farber. In 2010 together with Shirley Liu he founded the Center for Functional Cancer Epigenetics at the Dana-Farber. He was elected to the National Academy of Sciences in 2016 and the American Academy of Arts and Sciences in 2017. Myles' research is focused on providing a detailed understanding of the factors underlying the hormone dependence of breast and prostate cancers with the goal of developing new therapies and improving outcomes for patients."
          ],
          href:'https://scholar.google.com/citations?hl=en&user=wwxk-JMAAAAJ',
        }, {
          id: 12,
          name: 'Prof. Gordon Freeman',
          img: require("../assets/team/1/GordonFreemanPhD.png"),
          h2: "Professor of Medicine at Dana-Farber Cancer Institute and Harvard Medical School",
          line: [
            "Prof. Gordon Freeman is in the Department of Medical Oncology at Dana-Farber and is also professor of Medicine at Dana-Farber Cancer Institute and Harvard Medical School. Prof. Freeman's research identified the major pathways that control the immune response by inhibiting T cell activation (PD-1/PD-L1 and B7-2/CTLA-4) or stimulating T cell activation (B7-2/CD28). In 2000, he discovered PD-L1 and PD-L2, and showed they were ligands for PD-1, thus defining the PD-1 pathway and the drug target: block the interaction. He showed the function of PD-1 was to inhibit immune responses and that blockade enhanced immune responses. He showed that PD-L1 is highly expressed on many solid tumors such as breast and lung, as well as some hematologic malignancies and allows these tumors to inhibit immune attack. This work provided the foundation for developing immune checkpoint blockade immunotherapies. He is a Fellow of the AACR Academy and received the 2014 William B. Coley Award for Distinguished Research in Tumor Immunology, 2017 Warren Alpert Foundation award, and the 2020 Richard Smalley, MD, memorial award from the Society for Immunotherapy of Cancer."
          ],
          href:'https://scholar.google.com/citations?hl=en&user=SAKfVgEAAAAJ',
        }, {
          id: 13,
          name: 'Lewis Lanier, PhD',
          img: require("../assets/team/1/LewisLanier.png"),
          h2: "J. Michael Bishop, MD, Distinguished Professor in Microbiology and Immunology, UCSF",
          line: [
            "Dr. Lanier is an American Cancer Society Professor and J. Michael Bishop MD Distinguished Professor Emeritus at University of California San Francisco. Dr. Lanier received his B.S. in Biology from Virginia Tech and Ph.D. in Microbiology and Immunology from UNC – Chapel Hill. After postdoctoral studies, he joined the R&D Department at the Becton Dickinson Monoclonal Center, advancing to Associate Director. In 1990, he joined the DNAX Research Institute, where he advanced to Director of Immunobiology, and in 1999 joined the faculty of UCSF. His research group studies Natural Killer cells, which recognize and eliminate cells that have become transformed or infected by viruses. In recognition of his contributions he was awarded the William B. Coley Award for Distinguished Research in Basic Tumor Immunology from the Cancer Research Institute (2002), received the Rose Payne Award for contributions to the field of Immunogenetics by the American Society for Histocompatibility and Immunogenetics (2005), was elected to the US National Academy of Sciences (2010), and the American Academy of Arts and Sciences (2011). He served as President of the American Association of Immunologists (2006-2007), received the AAI Excellence in Mentoring Award in 2017, and awarded the AAI Lifetime Achievement Award in 2023."
          ],
          href:'',
        }, {
          id: 14,
          name: 'Kai Li, PhD',
          img: require("../assets/team/1/KaiLiPhD.png"),
          h2: "Paul M. Wythes and Marcia R. Wythes Professor of Computer Science at Princeton University",
          line: [
            "Prof. Kai Li is a Paul M. Wythes and Marcia R. Wythes Professor of Computer Science at Princeton University, where he served on the faculty since 1986. Prof. Li’s research areas include operating systems, parallel and distributed systems, storage systems, and analysis of big data. He pioneered Distributed Shared Memory (DSM), allowing shared-memory programming on a cluster of computers, which won the ACM SIGOPS Hall of Fame Award in 2012. He proposed user-level DMA mechanism for efficient cluster communication, which went into the RDMA standard of Infiniband. He co-led (with Prof. Fei-Fei Li) the ImageNet project, which propelled deep learning to become the most active research area in machine learning. He co-founded Data Domain, Inc. and led the innovation of deduplication storage system product line to replace tape libraries at data centers. The product line has taken over 60% of the market. The company went public in 2007 and later acquired by EMC. He was elected as an ACM fellow, an IEEE fellow and a member of National Academy of Engineering."
          ],
          href:'https://scholar.google.com/citations?hl=en&user=9MSpWOUAAAAJ',
        }, {
          id: 15,
          name: 'Mike Varney, PhD',
          img: require("../assets/team/1/MikeVarney.png"),
          h2: "Former EVP and Head, Genentech Research and Early Development",
          line: [
            "Dr. Mike Varney is a pioneer drug discoverer and biotech leader. As one of the original fifteen employees at Agouron, a biotech based in San Diego, he built a team that developed protein-structure based design, a novel approach to drug discovery that is utilized globally by drug discovery teams today. In 1997, the Agouron team launched Viracept®, an HIV protease inhibitor that achieved the highest first year launch sales of any biotech product at the time. Dr. Varney’s leadership at Agouron resulted in the discovery of a number of currently marketed anti-cancer agents, including Xalkori® and Inlyta®, a drug that won the American Chemical Society’s Heroes of Chemistry Award in 2018.",
            "In 2005, Dr. Varney was recruited to Genentech to expand the organization’s drug discovery capabilities to include small molecules. Focused on agility and pioneering science, Dr. Varney built a team-based organization that today produces more than 40% of Genentech’s development portfolio, including the marketed anti-cancer agents Erivedge® and Cotellic®.",
            "In 2015, Dr. Varney was appointed Executive Vice President and Head of Genentech’s Research and Early Development (gRED) and a member of the Roche Corporate Executive Committee. In this role, he was responsible for all aspects of gRED innovation, drug discovery and development. Dr. Varney also focused on building a team-based culture empowered to clear the path to patients. Under his leadership, gRED teams discovered and developed successful medicines that include Venclexta® with AbbVie, the first BCL-2 inhibitor, and Polivy™, an antibody drug conjugate for the treatment of DLBCL. Pioneering molecules in clinical development include mosunetuzumab, a bispecific antibody targeting CD20, and GDC-9545, a next-generation estrogen receptor degrader. Dr. Varney retired from Genentech in July 2020, leaving a diversity of drug platform types that includes personalized therapeutic vaccines and cellular therapies.",
            "Dr. Varney holds a B.S. in Chemistry from the University of California, Los Angeles, and a Ph.D. in synthetic organic chemistry from the California Institute of Technology, and was an American Cancer Society postdoctoral fellow at Columbia University. Dr. Varney is currently an advisor and Board member for a number of private and public biotechnology companies."
          ],
          href:'',
        }, {
          id: 16,
          name: 'Julie Cherrington, PhD',
          img: require("../assets/team/1/JulieCherrington.png"),
          h2: "Venture Partner, Brandon Capital Partners",
          line: [
            "Dr. Julie M Cherrington is an experienced life science executive with extensive insight in bringing drugs into the clinic and through to commercialization. She has been a key contributor to the successful development of multiple FDA-approved products, including SUTENT®, PALLADIA®, VISTIDE®, VIREAD®, and HEPSERA®.",
            "Dr. Cherrington is also an experienced company builder - she has served as President and Chief Executive Officer at several biotechnology companies, including QUE Oncology, Arch Oncology, Revitope Oncology, Zenith Epigenetics, and Pathway Therapeutics. In addition, she served as President and Executive Vice President, R&D at Phenomix Corporation, a diabetes and antiviral company. Earlier in her career, Dr. Cherrington was Vice President of Preclinical and Clinical Research at SUGEN, a Pharmacia/Pfizer company. Dr. Cherrington began her career at Gilead Sciences, where she held a range of positions of increasing responsibility. Dr. Cherrington holds a B.S. in biology and an M.S. in microbiology from the University of California, Davis. She received her Ph.D. training in microbiology and immunology from the University of Minnesota and Stanford University and completed a postdoctoral fellowship at the University of California, San Francisco.",
            "Dr. Cherrington is active in entrepreneurship initiatives through CLS, UC San Francisco, UC Davis and Equalize 2020/2021/2022.",
            "Currently, she is a Venture Partner at Brandon Capital Partners and serves on the Boards of Syncona Ltd, Sardona Therapeutics, KisoJi Biotechnology, MycRx, Vaxart, Mirati Therapeutics, and Actym Therapeutics (Chair)."
          ],
          href:'',
        }, {
          id: 17,
          name: 'Alessandra Cesano, MD, PhD',
          img: require("../assets/team/1/AlessandraCesano.png"),
          h2: "Chief Medical Officer of ESSA Pharmaceuticals",
          line: [
            "Alessandra Cesano, MD, PhD, currently serves as the chief medical officer of ESSA Pharmaceuticals.",
            "Prior to joining ESSA, Dr. Cesano was chief medical officer at NanoString Technologies, Inc., Cleave Biosciences, Inc. and before then she served as chief medical officer and chief operations officer at Nodality, Inc., where she built and led the research and development group, while providing the overall clinical vision for the organization. Between 1998 and 2008, Dr. Cesano held various management positions at Amgen, Biogen Idec and SmithKline Beecham Pharmaceuticals, where she helped to advance various oncology drugs through late stage development and FDA approvals.",
            "Early in her professional career Dr. Cesano spent 12 years conducting research in tumor immunology, including nine years at the Wistar Institute, an NCI Basic Cancer Center connected with the University of Pennsylvania.",
            "She also holds membership in several professional and scientific societies including ASCO, ESMO, ASH, EHA, AACR and SITC. In the latter she serves as co-chair in the SITC Industry Committee, associate editor for the biomarker section of JITC and is an active member of the SITC Biomarker Working Group.",
            "Over her career she has been an author on over 100 publications.",
            "Dr. Cesano received an MD summa cum laude, a board certification in oncology and a PhD in tumor immunology from the University of Turin (Turin, Italy)."
          ],
          href:'',
        }, {
          id: 18,
          name: 'Leila Alland, MD',
          img: require("../assets/team/1/LeilaAlland.png"),
          h2: "Board of Directors of Abeona Therapeutics, Board of Directors of Radiopharm Theranostics",
          line: [
            "Dr. Leila Alland is trained as a pediatric hematologist-oncologist and has been in biopharmaceutical drug development for over 20 years.  She currently serves as an advisor to biotech companies. She also serves on the Board of Directors of Abeona Therapeutics (NASDAQ: ABEO) and Radiopharm Theranostics (ASX: RAD and on the Advisory Council of Columbia University’s Center for Radiological Research.",
            "Previously, Leila served as CMO of PMV Pharmaceuticals (NASDAQ, PMVP), Affimed (NASDAQ: AFMD), and Tarveda Therapeutics. Her career includes leadership roles at AstraZeneca, Bristol-Myers Squibb, Novartis and Schering-Plough, where she contributed to the development of many approved drugs, including Tagrisso®, Opdivo®, Tasigna®, and Caelyx®.",
            "Leila received her B.A from U. Penn and M.D. from New York University. She completed training in Pediatrics at the Children’s Hospital of Philadelphia, and Hematology/Oncology at Memorial Sloan-Kettering, and served on the faculty of Albert Einstein College of Medicine during which she received multiple grants and awards for her research on oncoproteins and epigenetics."
          ],
          href:'',
        }, {
          id: 19,
          name: 'James Wooldridge, MD',
          img: require("../assets/team/1/JamesWooldridge.png"),
          h2: "Chief Medical Officer of Checkmate Pharmaceuticals, Chief Medical Officer of Aeglea BioTherapeutics",
          line: [
            "Dr. Wooldridge has more than 20 years of experience in clinical oncology research and drug development, spanning the biotechnology, pharmaceutical, and academic sectors. He previously served as the Chief Medical Officer at Checkmate Pharmaceuticals before the company's acquisition by Regeneron. He also held the role of Chief Medical Officer at Aeglea BioTherapeutics, where he oversaw development of enzyme-based treatments for rare genetic diseases. Prior to his work as a biotech executive, Dr. Wooldridge spent nearly 11 years heading cancer therapeutic development through various roles at Eli Lilly, most recently serving as Chief Scientific Officer, Immuno-oncology Clinical Development. Dr. Wooldridge also previously conducted clinical and translational cancer research as a faculty member at the University of Iowa and the University of Missouri. He earned his M.D. at the Tulane University School of Medicine and completed post-graduate training in internal medicine and medical oncology at the University of Iowa."
          ],
          href:'',
        },
      ],
      active: null
    }
  },
  beforeMount () {
    const { id } = this.$route.params
    this.active = this.list[id - 1]
  },
  components: {
    Footers
  }
}
</script>
<style scoped lang="scss">
.news {
  font-size: 30px;
  font-weight: 500;
  padding: 0 40px;
  .title {
    font-weight: bold;
    font-size: 50px;
    padding-top: 40px;
  }
  .info {
    color: #003e64;
    font-size: 40px;
  }
  .img {
    margin: 50px 0;
  }
  .time {
    font-size: 20px;
    color: #666666;
    font-style: italic;
    margin: 50px 0;
  }
  .item {
    .font-weight-bold {
      margin-bottom: 30px;
    }
  }
}
</style>
